<template>
  <div>
    <TheNavbar/>
    <div class="container mt-4">

      <!-- QR CODE GENERATOR TITLE -->
      <div class="row mb-3">
        <div class="col-md-12 animate fadeIn">
          <h1 class="fw-500 typography-headline-lg">Your restaurant's menu QR code</h1>
          <p class="paragraph mb-0">
            Print these QR codes and share them with your customers.
          </p>
        </div>
      </div>

      <!-- QR CODE GENERATOR -->
      <div class="row mb-3">
        <div class="col-md-12 mb-3">
          <div v-if="restaurantURL" class="card card-outline">
            <div class="card-body d-flex justify-content-between">
              <div>
                <p class="overline text-primary-contrast mb-2">URL link</p>
                <p class="typography-headline-md mb-2">
                  {{ restaurantURL }}
                </p>
              </div>
              <div class="align-self-center">
                <button @click="printQrCodes"
                        :disabled="isGeneratingPrintPage"
                        class="btn btn-primary ripple p-3">
                  {{ isGeneratingPrintPage ? 'Printing...' : 'Print QR codes' }}
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-6">

          <iframe :src="printURL"
                  style="width:0;height:0"></iframe>

          <div class="card card-outline">
            <div class="card-body text-center">
              <p class="typography-headline-md fw-bold text-uppercase text-body mt-3 mb-0">
                {{ restaurantData?.name ?? "Loading..." }}
              </p>

              <div class="m-3" v-if="restaurantURL">
                <QrcodeVue :value="restaurantURL"
                           :size="300"
                           render-as="svg"/>
              </div>
              <div v-else>
                Generating QR code...
              </div>

              <p class="paragraph fw-500 text-uppercase text-body mb-0">
                Scan this QR code for the restaurant menu
              </p>
              <p v-if="restaurantURL" class="subtitle-1 text-muted">
                Or visit <span class="fw-500">{{ restaurantURL }}
              </span></p>
            </div>
          </div>
        </div>
      </div>

    </div>

  </div>
</template>

<script>
import TheNavbar from "@/components/TheNavbar";
import {db, firebaseAuth} from '@/firebase'
import {doc, getDoc} from 'firebase/firestore'
import QrcodeVue from 'qrcode.vue'

export default {
  name: "QrCodeGenerator",
  components: {TheNavbar, QrcodeVue},
  mounted() {
    this.fetchRestaurantInfo()
  },
  data() {
    return {
      restaurantData: null,
      printURL: null,
      isGeneratingPrintPage: false,
    }
  },
  computed: {
    restaurantURL() {
      return this.restaurantData?.subdomain ? `${this.restaurantData.subdomain}.menuffy.com` : null
    }
  },
  methods: {
    printQrCodes() {
      this.isGeneratingPrintPage = true
      this.printURL = null
      this.printURL = !this.restaurantData
          ? null
          : this.$router.resolve({
            name: 'QRCodePrint',
            query: {
              url: this.restaurantURL,
              name: this.restaurantData.name,
            }
          }).href
      setTimeout(() => this.isGeneratingPrintPage = false, 3500)
    },
    async fetchRestaurantInfo() {
      const docRef = doc(db, 'restaurants', firebaseAuth.currentUser.uid)
      const docSnap = await getDoc(docRef)
      this.restaurantData = {id: docSnap.id, ...docSnap.data()}
    }
  }
}
</script>
